"use client";

import React, { HTMLAttributes, useState } from "react";

import { UserPuzzleHistory } from "@prisma/client";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";

interface LeaderboardProps {
  leaderboard: UserPuzzleHistory[];
  showHeader: boolean;
  gameMode: string;
}

const LeaderboardTable: React.FC<
  LeaderboardProps & HTMLAttributes<HTMLDivElement>
> = ({ leaderboard, showHeader, gameMode }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  let paginated: any;
  let totalPages;

  if (leaderboard && leaderboard.length > 0) {
    totalPages = Math.ceil(leaderboard.length / itemsPerPage);
    paginated = leaderboard.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage,
    );
  } else {
    totalPages = 0;
    paginated = [];
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbers = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
    let endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

    if (endPage - startPage + 1 < maxPageNumbers) {
      startPage = Math.max(1, endPage - maxPageNumbers + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <PaginationItem key={i}>
          <PaginationLink
            href="#"
            isActive={currentPage === i}
            onClick={(e) => {
              e.preventDefault();
              setCurrentPage(i);
            }}
          >
            {i}
          </PaginationLink>
        </PaginationItem>,
      );
    }

    return pageNumbers;
  };

  if (leaderboard && leaderboard.length === 0) {
    return (
      <div className="mb-2 mt-6 flex h-[30px] w-full items-center justify-center bg-[#f7f5ff] text-sm text-[#3f3f3f]">
        Kullanıcı bulunamadı
      </div>
    );
  } else {
    return (
      <div>
        <Table className="mt-4">
          {showHeader == true && (
            <TableHeader className="h-[30px] border-b-4 border-primary bg-[#f7f5ff] text-[#3f3f3f]">
              <TableRow>
                <TableHead className="h-10 w-20"></TableHead>
                <TableHead className="h-10">Kullanıcı adı</TableHead>
                <TableHead
                  className={`{gameMode ===  "puzzles" ? : text-center} h-10  text-right`}
                >
                  {gameMode === "puzzles" ? "Puan" : "Doğru Sayısı"}
                </TableHead>
              </TableRow>
            </TableHeader>
          )}
          <TableBody>
            {leaderboard &&
              gameMode == "puzzles" &&
              paginated.map((rank: any, index: number) => (
                <TableRow key={index}>
                  <TableCell className="pb-1.5 pt-1.5 ">
                    {currentPage == 1 && index == 0 && (
                      <div className="w-5 rounded-xl border border-[#f3de71] bg-[#fcfaf1] text-center text-[#e9c300]">
                        <span>{index + 1}</span>
                      </div>
                    )}
                    {currentPage != 1 && index == 0 && (
                      <div className="w-5 text-center text-[#7e7e7e]">
                        <span>
                          {index +
                            1 +
                            currentPage * itemsPerPage -
                            itemsPerPage}
                        </span>
                      </div>
                    )}
                    {currentPage == 1 && index == 1 && (
                      <div className="w-5 rounded-xl border border-[#9e9e9e] bg-[#eeeeee] text-center text-[#9e9e9e]">
                        <span>{index + 1}</span>
                      </div>
                    )}
                    {currentPage != 1 && index == 1 && (
                      <div className="w-5 text-center text-[#7e7e7e]">
                        <span>
                          {index +
                            1 +
                            currentPage * itemsPerPage -
                            itemsPerPage}
                        </span>
                      </div>
                    )}
                    {currentPage == 1 && index == 2 && (
                      <div className="w-5 rounded-xl border border-[#ffac35] bg-[#fff2df] text-center text-[#ffac35]">
                        <span>{index + 1}</span>
                      </div>
                    )}
                    {currentPage != 1 && index == 2 && (
                      <div className="w-5 text-center text-[#7e7e7e]">
                        <span>
                          {index +
                            1 +
                            currentPage * itemsPerPage -
                            itemsPerPage}
                        </span>
                      </div>
                    )}
                    {index != 0 && index != 1 && index != 2 && (
                      <div className="w-5 text-center text-[#7e7e7e]">
                        <span>
                          {currentPage == 1
                            ? index + 1
                            : index +
                              1 +
                              currentPage * itemsPerPage -
                              itemsPerPage}
                        </span>
                      </div>
                    )}
                  </TableCell>
                  <TableCell className="pb-1.5 pt-1.5 ">
                    {rank.UserLoginData.NickName}
                  </TableCell>
                  <TableCell className="pb-1.5 pt-1.5  text-right">
                    {rank.Rating}
                  </TableCell>
                </TableRow>
              ))}
            {leaderboard &&
              gameMode == "puzzleRush" &&
              paginated.map((item: any, index: number) => (
                <TableRow key={index}>
                  <TableCell className="pb-1.5 pt-1.5 ">
                    {index == 0 && (
                      <div className="w-5 rounded-xl border border-[#f3de71] bg-[#fcfaf1] text-center text-[#e9c300]">
                        <span>{index + 1}</span>
                      </div>
                    )}
                    {index == 1 && (
                      <div className="w-5 rounded-xl border border-[#9e9e9e] bg-[#eeeeee] text-center text-[#9e9e9e]">
                        <span>{index + 1}</span>
                      </div>
                    )}
                    {index == 2 && (
                      <div className="w-5 rounded-xl border border-[#ffac35] bg-[#fff2df] text-center text-[#ffac35]">
                        <span>{index + 1}</span>
                      </div>
                    )}
                    {index != 0 && index != 1 && index != 2 && (
                      <div className="w-5 text-center text-[#7e7e7e]">
                        <span>{index + 1}</span>
                      </div>
                    )}
                  </TableCell>
                  <TableCell className="  pb-1.5 pt-1.5 ">
                    {item.UserLoginData.NickName}
                  </TableCell>
                  <TableCell className=" pb-1.5 pt-1.5 text-center">
                    {item.max}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Pagination>
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentPage((prev) => Math.max(prev - 1, 1));
                }}
              />
            </PaginationItem>
            {renderPageNumbers()}
            {currentPage < totalPages - 2 && (
              <>
                <PaginationItem>
                  <PaginationEllipsis />
                </PaginationItem>

                <PaginationItem key={totalPages}>
                  <PaginationLink
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentPage(totalPages);
                    }}
                  >
                    {totalPages}
                  </PaginationLink>
                </PaginationItem>
              </>
            )}
            <PaginationItem>
              <PaginationNext
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages));
                }}
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </div>
    );
  }
};
export default LeaderboardTable;
